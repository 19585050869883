import React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

import MaxWidth from "components/functional/maxWidth";
import TodayTix from "svgs/todaytix.svg";

import { media } from "utils/Media";

const TicketPricesStyles = styled.section`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding: 3rem 0;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3rem;

  .small {
    width: 90%;
    margin: var(--auto);
    background: #f7a802;
    background: linear-gradient(180deg, #f7a802 0%, #f8c706 100%);
    box-shadow: 0 0 10px 10px #8c290d;
    border-radius: 15px;
    position: relative;
    text-align: center;
    padding: 5px;

    .wrapper {
      background-color: var(--black);
      border-radius: 10px;
      padding: 1.5rem;

      h4,
      strong {
        text-transform: uppercase;
      }

      h4 {
        font-size: 1.5rem;
        @media ${media.lg} {
          font-size: 2.5rem;
        }
      }

      > strong {
        display: block;
        color: var(--white);
        font-size: 1.5rem;
        margin-top: 2.5rem;
        @media ${media.lg} {
          font-size: 2rem;
        }
      }

      .icon {
        width: 80%;
        max-width: 150px;
        margin: 0 auto 1rem auto;

        .gatsby-image-wrapper {
          width: 100%;
          height: auto;
        }
      }

      .grid {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;

        strong {
          text-transform: uppercase;
          color: var(--mainYellow);
          font-size: 1.1rem;
          margin: 0.5rem 0;
        }

        p {
          margin-top: 0.5rem;
        }

        @media ${media.md} {
          grid-template-columns: repeat(2, 1fr);
          margin: 2rem auto;
          max-width: 900px;
          strong {
            font-size: 1.25rem;
          }

          .span-two {
            grid-column: 1 / span 2;
          }
        }
      }

      .small-border-mobile {
        width: 100%;
        height: auto;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .large {
    width: 90%;
    margin: var(--auto);
    background: #f7a802;
    background: linear-gradient(180deg, #f7a802 0%, #f8c706 100%);
    border-radius: 15px;
    position: relative;
    text-align: center;
    padding: 5px;
    box-shadow: 0 0 10px 10px #8c290d;

    .wrapper {
      background-color: var(--black);
      border-radius: 10px;
      padding: 1.5rem;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 2rem;

      section {
        position: relative;
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        margin-top: 0.25rem;

        p {
          margin-bottom: 1rem !important;
          max-width: 650px !important;
        }

        .icon {
          width: 60%;
          height: auto;
          margin: 0 auto 0.5rem auto;
          max-width: 150px;

          .gatsby-image-wrapper {
            width: 100%;
            height: 100%;
          }
        }

        .larger {
          width: 90%;
          max-width: 320px;
        }

        strong {
          text-transform: uppercase;
          color: var(--mainYellow);
          font-size: 1.1rem;
          margin: 0.5rem 0;
          @media ${media.md} {
            font-size: 1.45rem;
          }
        }

        .bold {
          font-weight: 800;
          margin: 0.5rem auto 0.25rem auto;
        }

        .todaytix {
          margin: 1rem auto;
          width: 150px;
        }

        p {
          max-width: 700px;
          margin-bottom: 0.25rem;
          text-align: center;
          margin: var(--auto);
        }

        .link {
          a {
            color: var(--main);
          }
        }
      }
    }
  }
`;
const TicketPrices = () => {
  return (
    <MaxWidth>
      <TicketPricesStyles>
        <div className="small">
          <div className="wrapper">
            <div className="icon">
              <StaticImage
                src="../../images/icons/tickets.png"
                alt="Rush Tickets | The Mousetrap"
                placeholder="none"
              />
            </div>
            <h4>Ticket Prices</h4>
            <div className="grid">
              <div>
                <strong>TUESDAY TO THURSDAY</strong>

                <p>
                  <br />
                  Ticket prices range from £25 to £62.50
                  <br />
                  Premium seats available at £79/£99
                </p>
              </div>
              <div>
                <strong>MONDAY & FRIDAY</strong>
                <br />
                <p>
                  <br />
                  Ticket prices range from £27.50 to £67.50
                  <br />
                  Premium seats available at £85/£99
                </p>
              </div>
              <div className="span-two">
                <strong>
                  SATURDAY:
                  <br /> (AND PEAK WEEKS)
                </strong>
                <p>
                  <br />
                  Ticket prices range from £29.50 to £70
                  <br />
                  Premium seats available at £89 and £99
                </p>
              </div>
            </div>
            <p className="mt-4">Peak weeks are the weeks commencing:</p>

            <p>2024: 12 February, from 29 March &ndash; 12 April, 27 May, 29 July, 5 August, 12 August, 19 August, 26 August.</p>
          </div>
        </div>
        <div className="large">
          <div className="wrapper">
            <section>
              <div className="icon">
                <StaticImage
                  src="../../images/icons/seats.png"
                  alt="Day Seats | The Mousetrap"
                  placeholder="none"
                />
              </div>
              <strong>Day seats</strong>
              <p>
                A limited number of Day Seats are also available for every
                performance at £22.50 each. Day seats will be released to buy
                online or in person at 10am Monday to Saturday. Ticket sales are
                strictly limited to two tickets per person. The Box Office
                Manager reserves the right to refuse sale if they feel the seats
                are being bought for resale by ticket touts.
              </p>
            </section>
            <section>
              <div className="icon">
                <StaticImage
                  src="../../images/icons/tickets.png"
                  alt="Rush Tickets | The Mousetrap"
                  placeholder="none"
                />
              </div>
              <strong>Rush Tickets</strong>
              <p>
                For every performance, a limited number of &pound;25 rush
                tickets will be available from 10am in the Todaytix app. For
                more information and to book visit{" "}
                <a
                  href="https://www.todaytix.com/london/shows/333-the-mousetrap"
                  target="_blank"
                  rel="noreferrer"
                >
                  todaytix.com
                </a>
              </p>
              <TodayTix className="todaytix" />
            </section>
            {/* <section>
              <div className="icon">
                <StaticImage
                  src="../../images/icons/fourpeople.png"
                  alt="Group Offer | The Mousetrap"
                  placeholder="none"
                />
              </div>
              <strong>
                THE ‘WEDUNNIT’ FAMILY AND FRIENDS OFFER: BUY 3, GET 1 FREE!
              </strong>
              <p>Agatha Christie&rsquo;s THE MOUSETRAP is more than a play &mdash; it&rsquo;s a can&rsquo;t-miss London destination, a joyful rite of passage for theatre lovers of all ages, and an enduring family tradition shared across generations. Nothing&rsquo;s quite as fun as playing detective from your seat, knowing every colourful character is a suspect, and trying to figure out WHODUNNIT before everyone else. Plus, it&rsquo;s a great introduction to the suspenseful brilliance of the world&rsquo;s best-selling novelist &mdash; the one and only Agatha Christie! Whether it&rsquo;s your first time seeing a West End play or you&rsquo;re a lifelong theatre fan, there&rsquo;s never been a better time to get caught in THE MOUSETRAP. Don&rsquo;t just SEE IT this AUTUMN. SOLVE IT this Autumn, with the unbeatable &ldquo;WEDUNNIT&rdquo; Family Offer!</p>
              <p>See THE MOUSETRAP in the AUTUMN with friends and family and get 1 ticket free when you buy 3 for Monday &ndash; Friday shows. Valid for performances in from September 1st to 21 October.</p>
              <p>Click on the link below and the offer will automatically be applied. All you need to do is choose where you would like to sit:</p>
              <strong className="link">
                <Link to="/book-tickets/?flow=DTCC2Scv#/">
                  BUY TICKETS HERE
                </Link>
              </strong>
            </section> */}
            <section>
              <div className="icon larger">
                <StaticImage
                  src="../../images/icons/Group.png"
                  alt="Group Offer | The Mousetrap"
                  placeholder="none"
                />
              </div>
              <strong>Groups</strong>
              <p>Get a group together and save at least &pound;25.</p>
              <p>
                Monday to Friday 10+ for £37.50 valid on Band
                A and C (£59.50/£65) tickets. Tuesday & Thursday matinee rate
                10+ for £24.75 on Band B (£49.50/£52.50) tickets. Exclusions
                apply.
              </p>
              <p>
                Excluded weeks are weeks commencing 12 February.
              </p>
              <p>
                Please note that a maximum of nine tickets can be purchased
                online. For any bookings of ten or more, please contact the box
                office on 020 7836 1443.
              </p>
            </section>
            <section>
              <div className="icon larger">
                <StaticImage
                  src="../../images/icons/Education.png"
                  alt="Edcuation Offer | The Mousetrap"
                  placeholder="none"
                />
              </div>
              <strong>EDUCATION GROUPS OFFER</strong>
              <p>
                Get one free teacher ticket with every ten
                tickets booked. Valid Monday to Thursday including matinees.
                Band A (£59.50/£65) reduced to £27.50 + 1 free teacher per 10
                paid. Band B or C (up to £49.50/£52.50) reduced to £19.50 + 1
                free teacher per 10 paid.
              </p>

              <p>
                Excluding week 12 February.
              </p>

              <p>To book, please contact the box office on 020 7836 1443.</p>
            </section>
          </div>
        </div>
      </TicketPricesStyles>
    </MaxWidth>
  );
};

export default TicketPrices;
